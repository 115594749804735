var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"vue-select-modified",attrs:{"label":_vm.label,"label-for":_vm.labelFor}},[_c('v-select',{ref:"inputSelect",class:("vue-select-multiple-component " + (_vm.stateField == false ? 'is-invalid-vue-select' : '')),attrs:{"placeholder":_vm.placeholder,"options":_vm.options,"state":_vm.stateField,"readonly":false,"multiple":"","deselectFromDropdown":true,"closeOnSelect":false,"taggable":_vm.taggable,"push-tags":_vm.pushTags,"create-option":_vm.createOption,"clear-search-on-blur":_vm.addSearchOnBlur},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search input-multi-select-tk",on:{"focus":_vm.removeReadonly}},'input',attributes,false),events))]}},{key:"open-indicator",fn:function(ref){return [_c('span',[_c('ArrowDownIcon')],1)]}},{key:"list-footer",fn:function(){return [_c('li',{staticClass:"fixed"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableSelectAll),expression:"enableSelectAll"}],staticClass:"bar-select-all"},[_c('div',{staticClass:"btn-select-all",on:{"click":_vm.selectAll}},[_vm._v(" "+_vm._s(_vm.$t('SelectAll'))+" ")])])])]},proxy:true},{key:"selected-option-container",fn:function(ref){
var option = ref.option;
var deselect = ref.deselect;
return [_c('div',{class:(_vm.selecteds.map(function (item) { return item.value; }).indexOf(option.value) <= _vm.countChip &&
            !_vm.oneElementBroken) ||
          (_vm.selecteds.map(function (item) { return item.value; }).indexOf(option.value) < _vm.countChip &&
            _vm.oneElementBroken)
            ? 'vs__selected'
            : ''},[(
            (_vm.oneElementBroken == false &&
              _vm.selecteds.map(function (item) { return item.value; }).indexOf(option.value) < _vm.countChip) ||
            _vm.flagSelectResume == false
          )?_c('span',[_vm._v(" "+_vm._s(option.label)+"   "),_c('button',{staticClass:"vs__deselect vue-close-select",attrs:{"type":"button"},on:{"click":function($event){return deselect(option)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"10"}},[_c('path',{attrs:{"d":"M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"}})])])]):_vm._e(),(
            (_vm.selecteds.map(function (item) { return item.value; }).indexOf(option.value) == _vm.countChip &&
              _vm.flagSelectResume &&
              !_vm.oneElementBroken) ||
            (_vm.selecteds.map(function (item) { return item.value; }).indexOf(option.value) + 1 <= _vm.countChip &&
              _vm.flagSelectResume &&
              _vm.oneElementBroken)
          )?_c('span',{class:'select-resume'},[_vm._v(" + "+_vm._s(!_vm.oneElementBroken ? _vm.selecteds.length - _vm.countChip : _vm.selecteds.length)+" ... ")]):_vm._e()])]}}]),model:{value:(_vm.selecteds),callback:function ($$v) {_vm.selecteds=$$v},expression:"selecteds"}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }