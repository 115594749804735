<template>
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1">
    <b-form>
      <b-row>
        <b-col md="12">
          <b-form-group
            :label="$t('WorkcenterList.Name')"
            label-for="name"
            :invalid-feedback="
              flagNameEqual ? $t('WorkcenterList.WorkcenterNameAlreadyExist') : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('WorkcenterList.Placeholder')"
              :state="stateName"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <VSelectChips
            key="select-chips-workcenter-add-resource"
            :label="$t('WorkcenterList.Resources')"
            :labelFor="'resources'"
            :placeholder="$t('WorkcenterList.Select')"
            :valueSelectMultiple="resources"
            :options="resourcesList"
            :stateField="stateResource"
            @updateData="(value) => (resources = value)"
          >
            <span
              class="is-invalid-span"
              v-if="stateResource == false"
              >{{ $t('RequiredField') }}</span
            >
          </VSelectChips>
        </b-col>

        <b-col md="12">
          <VSelectChips
            key="select-chips-workcenter-edit-user"
            :label="$t('WorkcenterList.Users')"
            :label-for="'users'"
            :placeholder="$t('WorkcenterList.Select')"
            :valueSelectMultiple="users"
            :options="usersList"
            :stateField="stateUser"
            @updateData="(value) => (users = value)"
          >
            <span
              class="is-invalid-span"
              v-if="stateUser == false"
              >{{ $t('RequiredField') }}</span
            >
          </VSelectChips>
        </b-col>

        <b-col
          md="12"
          class="col-buttons"
        >
          <b-form-group>
            <b-button
              v-if="!sendingForm"
              variant="primary"
              class="form-control btn-save-workcenter"
              @click="createWorkcenter()"
            >
              {{ $t('WorkcenterList.Save') }}
            </b-button>

            <b-button
              v-if="sendingForm"
              variant="primary"
              disabled
              class="form-control btn-save-workcenter"
            >
              <b-spinner small />
              Loading ...
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import flatPickr from 'vue-flatpickr-component';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

  import {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea
  } from 'bootstrap-vue';
  import VSelectChips from '@/@core/components/v-select/vSelectChips.vue';

  export default {
    components: {
      BAvatar,
      BListGroup,
      BListGroupItem,
      BCardText,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BButton,
      BSpinner,
      BRow,
      BCol,
      BFormTextarea,
      flatPickr,
      ToastificationContent,
      VSelectChips
    },
    directives: {
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        name: '',
        resources: null,
        resourcesList: [],
        users: null,
        usersList: [],
        stateName: null,
        stateResource: null,
        stateUser: null,
        flagNameEqual: false,
        sendingForm: false
      };
    },
    props: {
      nameWorkcenters: {
        type: Array
      }
    },
    created() {
      this.getResources();
      this.getUsers();
      this.watchClickOutsideModal();
    },
    methods: {
      getResources() {
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/workcenter/context?site_guid=${this.currentSite.guid}`
          )
          .then((response) => {
            const { data } = response;
            data.forEach(({ id, name }) => this.resourcesList.push({ label: name, value: id }));
          })
          .catch((error) =>
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
          );
      },
      getUsers() {
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/users?site_guid=${this.currentSite.guid}`
          )
          .then((response) => {
            const data = response.data.response;
            data.forEach(({ first_name, last_name, guid }) =>
              this.usersList.push({ label: `${first_name} ${last_name}`, value: guid })
            );
          })
          .catch((error) =>
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')
          );
      },
      validaCampos() {
        let nameEqual = this.nameWorkcenters.find((item) => item == this.name);
        this.flagNameEqual = nameEqual && this.name ? true : false;

        this.stateName = this.name && this.flagNameEqual == false ? null : false;
        this.stateResource = this.resources != null && this.resources.length > 0 ? null : false;
        this.stateUser = this.users != null && this.users.length > 0 ? null : false;
      },
      createWorkcenter() {
        this.validaCampos();
        if (this.stateName != false && this.stateResource != false && this.stateUser != false) {
          let resourcesIds = [];
          this.resources.forEach((el) => resourcesIds.push(el.value));
          let usersIds = [];
          this.users.forEach((el) => usersIds.push(el.value));

          this.sendingForm = true;
          this.$http
            .post(`/api/${this.$router.currentRoute.params.prefix}/workcenter`, {
              site_guid: this.currentSite.guid,
              name: this.name,
              resources: resourcesIds,
              users: usersIds
            })
            .then((res) => {
              this.sendingForm = false;
              this.$emit('refetch-data');
              this.$emit('show-message');
              this.showToast(
                this.$t('WorkcenterList.Success'),
                'SmileIcon',
                this.$t('WorkcenterList.CreatedText'),
                'success'
              );
            })
            .catch((error) => {
              this.sendingForm = false;
              this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
            });
        }
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      watchClickOutsideModal() {
        window.addEventListener('click', (element) => {
          if (
            element.target.id === 'modal-add-workcenter' &&
            document.getElementsByClassName('vs__dropdown-menu').length == 0
          )
            this.$bvModal.hide('modal-add-workcenter');
        });
      }
    }
  };
</script>

<style lang="scss">
  // @import '~@core/scss/vue/libs/vue-select.scss';
  #modal-add-workcenter {
    .form-control.is-invalid {
      background-image: none !important;
    }

    .vue-close-select {
      svg path {
        fill: #fff !important;
      }
    }
  }

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }

  .col-buttons {
    display: flex;
    justify-content: end;
    .btn-save-workcenter {
      width: auto;
      align-items: center;
      display: flex;
      background: #974900 !important;
      border-radius: 5px !important;
      border-color: #974900 !important;
      font-weight: 600;
      font-size: 17px;
      span {
        margin-right: 5px;
      }
    }

    &:focus {
      background: #974900 !important;
      border-color: #974900 !important;
    }
  }

  .is-invalid-span {
    width: 100%;
    font-size: 0.857rem;
    color: #ea5455;
  }
</style>
