<template>
  <b-form-group
    :label="label"
    :label-for="labelFor"
    class="vue-select-modified"
  >
    <v-select
      ref="inputSelect"
      :placeholder="placeholder"
      v-model="selecteds"
      :options="options"
      :state="stateField"
      :class="`vue-select-multiple-component ${stateField == false ? 'is-invalid-vue-select' : ''}`"
      :readonly="false"
      multiple
      :deselectFromDropdown="true"
      :closeOnSelect="false"
      :taggable="taggable"
      :push-tags="pushTags"
      :create-option="createOption"
      :clear-search-on-blur="addSearchOnBlur"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search input-multi-select-tk"
          v-bind="attributes"
          v-on="events"
          @focus="removeReadonly"
        />
      </template>

      <template #open-indicator="{}">
        <span>
          <ArrowDownIcon />
        </span>
      </template>

      <template #list-footer>
        <li class="fixed">
          <div
            class="bar-select-all"
            v-show="enableSelectAll"
          >
            <div
              class="btn-select-all"
              @click="selectAll"
            >
              {{ $t('SelectAll') }}
            </div>
          </div>
        </li>
      </template>

      <template #selected-option-container="{ option, deselect }">
        <div
          :class="
            (selecteds.map((item) => item.value).indexOf(option.value) <= countChip &&
              !oneElementBroken) ||
            (selecteds.map((item) => item.value).indexOf(option.value) < countChip &&
              oneElementBroken)
              ? 'vs__selected'
              : ''
          "
        >
          <span
            v-if="
              (oneElementBroken == false &&
                selecteds.map((item) => item.value).indexOf(option.value) < countChip) ||
              flagSelectResume == false
            "
          >
            {{ option.label }} &nbsp;

            <button
              type="button"
              class="vs__deselect vue-close-select"
              @click="deselect(option)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
              >
                <path
                  d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                ></path>
              </svg>
            </button>
          </span>
          <span
            v-if="
              (selecteds.map((item) => item.value).indexOf(option.value) == countChip &&
                flagSelectResume &&
                !oneElementBroken) ||
              (selecteds.map((item) => item.value).indexOf(option.value) + 1 <= countChip &&
                flagSelectResume &&
                oneElementBroken)
            "
            :class="'select-resume'"
          >
            + {{ !oneElementBroken ? selecteds.length - countChip : selecteds.length }} ...
          </span>
        </div>
      </template>
    </v-select>
    <!-- invalid feedback -->
    <slot></slot>
  </b-form-group>
</template>

<script>
  import Ripple from 'vue-ripple-directive';

  import { BFormGroup } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import ArrowDownIcon from '@/assets/images/pages/arrow-down-dark.svg';

  export default {
    components: {
      BFormGroup,
      vSelect,
      ArrowDownIcon
    },
    directives: {
      Ripple
    },
    props: {
      label: {
        default: ''
      },
      labelFor: {
        default: ''
      },
      placeholder: {
        default: ''
      },
      valueSelectMultiple: {
        type: Array,
        default: function () {
          return [];
        }
      },
      options: {
        type: Array,
        default: []
      },
      stateField: {
        default: ''
      },
      enableSelectAll: {
        type: Boolean,
        default: true
      },
      taggable: {
        type: Boolean,
        default: false
      },
      pushTags: {
        type: Boolean,
        default: false
      },
      createOption: {
        type: Function
      },
      setOnBlur: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        countChip: 0,
        flagSelectResume: false,
        selecteds: null,
        componentCreated: true,
        componentUpdated: false,
        counterWhenBroken: 0,
        oneElementBroken: false,
        vSelectHeight: 0,
        vSelect: null,
        vSelectWidth: 0,
        searchElement: null,
        vSelectSearch: null
      };
    },
    methods: {
      controlChips() {
        const vSelect = this.$children[0].$children[0].$refs.selectedOptions;

        const chips = vSelect.querySelectorAll('.vs__selected');

        if (chips?.length > 0) {
          const chipResume = vSelect.getElementsByClassName('select-resume')[0];
          if (
            !(
              (this.selecteds?.length == this.countChip && chipResume === undefined) ||
              chipResume === undefined ||
              this.selecteds?.length == this.counterWhenBroken - 1
            )
          )
            return;

          this.flagSelectResume = false;
          this.countChip = 0;
          this.counterWhenBroken = 0;
          this.oneElementBroken = false;

          const pixelsFree = 10;
          const vSelectWidth = vSelect.offsetWidth;
          const vSelectSearch = vSelect.querySelector('.vs__search.input-multi-select-tk');
          const lengthSelectedsElements = this.selecteds?.length;
          const chipResumeDefault = 49;
          const percOptionWidth = 0.6;
          const minSearchWidth = 30;
          let vSelectSearchWidth = vSelectSearch.offsetWidth;

          let sum = 0;
          let countChip = 0;

          for (let chip of chips) {
            sum += chip.offsetWidth + 7;

            vSelectSearch.style.maxWidth = `${(vSelectWidth - sum) * percOptionWidth}px`;
            vSelectSearchWidth = vSelectSearch.offsetWidth;

            if (sum + vSelectSearchWidth + pixelsFree < vSelectWidth && chip != chipResume) {
              countChip += 1;
            } else if (
              sum + vSelectSearchWidth + pixelsFree > vSelectWidth &&
              chip != chipResume &&
              lengthSelectedsElements == 1
            ) {
              countChip += 1;
              vSelectSearch.style.maxWidth = `${
                (vSelectWidth - chipResumeDefault) * percOptionWidth
              }px`;
              this.flagSelectResume = true;
              this.counterWhenBroken = lengthSelectedsElements;
              this.oneElementBroken = true;
            } else {
              if (!this.flagSelectResume) {
                this.counterWhenBroken = lengthSelectedsElements;
                if (
                  sum - chip.offsetWidth + chipResumeDefault + pixelsFree + minSearchWidth >=
                  vSelectWidth
                ) {
                  vSelectSearch.style.maxWidth = `${minSearchWidth}px`;
                  countChip -= 1;
                }

                if (sum + chipResumeDefault + pixelsFree + minSearchWidth <= vSelectWidth) {
                  vSelectSearch.style.maxWidth = `${minSearchWidth}px`;
                  countChip += 1;
                }
              }
              this.flagSelectResume = true;
            }
          }

          this.countChip = countChip;
        }
      },
      removeReadonly() {
        let nodes = document.querySelectorAll('.input-multi-select-tk');

        for (let elem of nodes) {
          elem.removeAttribute('readonly');
        }
      },
      addSearchOnBlur() {
        if (this.setOnBlur) {
          const select = this.$refs.inputSelect;
          const newOption = { id: select.search, label: select.search };

          if (!select.search) {
            return false;
          }

          select.select(newOption);

          return false;
        }
      },
      async selectAll() {
        this.selecteds = [];
        await this.options.forEach((option) => setTimeout(() => this.selecteds.push(option), 100));
      }
    },
    updated() {
      this.controlChips();
    },
    watch: {
      selecteds(v) {
        if (v != this.valueSelectMultiple) {
          this.$emit('updateData', v);
        }

        if (v?.length == 0) {
          this.oneElementBroken = false;
          this.vSelectSearch.style.maxWidth = '100%';
        }
      },
      async valueSelectMultiple(v) {
        if (v?.length > 0 && v != this.selecteds) {
          const setItens = async () => {
            this.selecteds = [];
            await v?.forEach((e) => {
              setTimeout(() => this.selecteds.push(e), 100);
            });
          };

          await setItens();
        }
      }
    },
    mounted() {
      this.vSelect = this.$children[0].$children[0].$refs.selectedOptions;
      this.vSelectHeight = this.vSelect.offsetHeight;
      this.vSelectSearch = this.vSelect.querySelector('.vs__search.input-multi-select-tk');
    }
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #vs2__listbox {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px !important; /* width of the entire scrollbar */
      border-radius: 50%;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fbeee8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #cfc4be; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 10px solid transparent;
    }
  }
  .vue-select-modified {
    .is-invalid-vue-select {
      .vs__dropdown-toggle {
        border-color: #ea5455;
      }
    }

    .form-control.is-invalid {
      background-image: none !important;
    }

    .vue-close-select {
      svg path {
        fill: #fff !important;
      }
    }

    .vs__actions {
      padding-top: 0 !important;
      svg {
        margin-right: 14px;
        height: 6.25px;
        width: 10px;
      }
    }

    .vs__dropdown-menu {
      max-height: 30vh !important;
    }
  }
  li.fixed {
    position: sticky;
    bottom: 0;
    background: #ffffff;
  }
  .bar-select-all {
    border-top: 1px solid #cfc4be;
    display: flex;
    padding: 12px 0 20px 0;
    justify-content: center;
    .btn-select-all {
      padding: 4px 14px;
      color: #974900;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
        background-color: #ffede2;
      }
      &:focus {
        background-color: #ffdbc4;
      }
    }
  }
</style>
